<template>
  <div>
    <div>
      <h3 class="flix-html-h3">{{ $tc('message.reminder', 2) }}</h3>
      <hr class="flix-html-hr" />
      <div v-if="!data.length">
        <div class="flix-alert flix-alert-danger">{{ $tc('message.noEntry', 2) }}</div>
        <addBtn :onClick="function () { setNew() }"><template v-slot:text>{{ $t('message.add', {name: $tc("message.reminder", 2)}) }}</template></addBtn>
      </div>
      <transition name="flixFadeIn">
        <div :key="edit.length + '' + deleteItem">
          <deleteItem v-if="deleteItem !== false" :data="editData[deleteItem]" :item="deleteItem" :onClose="function () { deleteItem = false }" :onDelete="setRemove" />
          <edit v-if="edit.length" :data="edit" :onClose="setCloseEdit" :onRemove="function() { deleteItem = edit[0]; edit = false }" />
          <div v-if="data.length && !edit.length && deleteItem === false">
            <timeline :data="data" :key="JSON.stringify(data)" :onRemove="checkRemove" :onAdd2="setAdd" :onNew="function() {setAdd(0)}">
              <template>
                <div class="flix-form-group flix-text-center">
                  <div class="flix-text-center">
                    *** <small class="flix-html-small flix-html-strong status"></small> ***
                  </div>
                  <div class="flix-html-h3" style="margin-top: 10px">
                    <flixIcon :id='"pending-notifications"' /> <span class="title"></span>
                  </div>
                  <span class="date2"></span>
                  <div class="flix-html-h2">
                    <flixIcon :id='"arrow-right"' />
                    <span class="format"></span>
                  </div>
                </div>
                <editBtn :settings="{block: true}" :onClick="function(data) { getEdit(data) }">
                  <template v-slot:text>
                    {{ $t('message.edit', {name: $tc('message.reminder', 1)}) }}
                  </template>
                </editBtn>
              </template>
            </timeline>
            <div class="end"><h1 class="flix-html-h1"><flixIcon :id="'important-day'" /></h1> {{ $tc('message.appointments', 1) }} {{ $t('message.begin') }}</div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    timeline () { return import('@/components/reminder/timeline') },
    edit () { return import('@/components/reminder/edit') },
    deleteItem () { return import('@/components/reminder/delete') }
  },
  props: {
    rawData: Array
  },
  data () {
    return {
      deleteItem: false,
      data: false,
      edit: [],
      editData: {}
    }
  },
  methods: {
    setNew (id) {
      var d = new Date()
      this.getOrderData([{
        ID: d.getTime(),
        format: 'email',
        to: this.$route.params.id,
        field: '',
        title: this.$tc('message.reminder', 1),
        msg: this.$t('reminder.standard'),
        survey: '',
        manual: '',
        trigger_int: 1,
        trigger_format: 'days',
        trigger_prepend: 'before',
        trigger_target: 'appointment begin',
        trigger_specific_date: '',
        date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes(),
        status: 'active'
      }])
      this.handleData()
      this.edit = [0, this.editData[0]]
    },
    checkRemove (id) {
      this.deleteItem = id
    },
    setAdd (id) {
      this.data.splice(id, 0, JSON.parse(JSON.stringify(this.data[id])))
      this.editData.splice(id, 0, JSON.parse(JSON.stringify(this.editData[id])))
      this.editData[(id + 1)].ID = new Date().getTime()
      this.edit = [(id + 1), this.editData[(id + 1)]]
      this.$store.dispatch('saveReminder', { ID: this.$route.params.id, data: this.editData })
    },
    setRemove (id) {
      this.data.splice(id, 1)
      this.editData.splice(id, 1)
      this.$store.dispatch('saveReminder', { ID: this.$route.params.id, data: this.editData })
      this.deleteItem = false
    },
    setCloseEdit () {
      var edit = this.edit
      this.editData[edit[0]] = edit[1]
      this.getOrderData(JSON.parse(JSON.stringify(this.editData)))
      this.handleData()
      this.$store.dispatch('saveReminder', { ID: this.$route.params.id, data: this.editData })
    },
    getEdit (event) {
      var index = Array.from(document.getElementsByClassName('flix-btn-edit')).indexOf(event.target)
      this.edit = [index, this.editData[index]]
    },
    getData () {
      return this.data
    },
    setData () {
      this.getOrderData(this.rawData)
      this.handleData()
    },
    getOrderData (data) {
      this.editData = {}
      this.data = {}
      data.forEach(function (d) {
        if (d.trigger_target.indexOf('appointment') === -1) {
          return true
        }

        var id = (d.trigger_int * 1)
        if (d.trigger_format === 'days') {
          id = id * (60 * 60 * 24)
        }
        if (d.trigger_format === 'hours') {
          id = id * (60 * 60)
        }
        var k = id * 1 + (Math.random(0.1, 0.9))
        this.editData[k] = d
        this.data[k] = this.getDataObject(d)
      }.bind(this))
    },
    getDataObject (d) {
      var msg = d.trigger_format
      if ((d.trigger_int * 1) === 1) {
        msg = msg.substring(0, msg.length - 1)
      }
      return {
        format: 'per ' + this.$t('reminder.' + d.format),
        title: d.title,
        date: '- ' + d.trigger_int + '<br />' + this.$t('message.' + msg),
        status: this.$t('message.' + d.status),
        date2: d.trigger_int + ' ' + this.$t('message.' + msg) + ' ' + this.$t('message.beforeAppointmentStarts')
      }
    },
    handleData () {
      var d = []
      var e = []
      var keys = Object.keys(this.data)
      keys.sort(function (a, b) {
        if (a * 1 > b * 1) {
          return -1
        }
        if (b * 1 > a * 1) {
          return 1
        }
        return 0
      })

      keys.forEach(function (k) {
        d.push(this.data[k])
        e.push(this.editData[k])
      }.bind(this))
      this.data = d
      this.editData = e
      this.edit = false
    }
  },
  mounted () {
    this.setData()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .end
    border: 1px solid #3f51b5
    margin-top: 20px
    text-align: center
    padding: 20px
    box-shadow: 0px 10px 10px -3px #bbb
  @media (max-width: 500px)
    .flix-html-h3
      font-size: 10pt
      margin-bottom: 40px
    .flix-html-h2
      font-size: 13pt
      margin-bottom: 40px
    .flix-btn
      font-size: 7pt
</style>
